.Header {
    color: white; 
    background-color:rgb(146, 141, 141);   
    position: fixed;
    padding-top: 10px;
    padding-bottom:10px;
    padding-left: 15px;
    padding-right: 15px;
    left:0;
    top: 0;
    right:0;
    z-index: 999;
  }