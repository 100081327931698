#tabulator {    
    max-height: 400px;
    max-width: 900px;
    width: auto;   
    height: auto;
    justify-content: center;
    margin: 0 auto;
  }
  #tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
    white-space: normal !important;
}