.NavReport {
    color: white; 
    
    padding-top: 45px;
    padding-bottom:10px;
    padding-left: 5px;
    padding-right: 5px;
    left:0;
    bottom:0;
    right:0;
    top: 10;
  }