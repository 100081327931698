.tabs-container {
    width: auto;    
    display: flex;
    flex-direction: column;
  }
  
  .tab {
    border: 1px solid gray;
    cursor: pointer;
    padding: 10px;
    border: 0px;
    border-bottom: none;
    font-size: 12px;
    text-align: center;
  }
  
  .tab.active {
    border: 1px solid gray;
    background-color: lightgray;
    font-weight: bold;
  }
  
  .tab-content {
    width: auto; 
    
  }