.rstm-toggle-icon {
    display: inline-block; }
    .rstm-toggle-icon-symbol {
      width: 1rem;
      height: 1rem;
      text-align: left;
      line-height: 2rem; }
  
  .rstm-tree-item-group {
    list-style-type: none;
    padding-left: 0;
    border-top: 1px solid #ccc;
    text-align: left;
    width: 100%; 
    font-size: 16px;
    
  }
  
  .rstm-tree-item {    
    font-size: 14px;
    padding: 0.1rem 0.5rem;
    cursor: pointer;
    color: #333;
    background: none;
    border-bottom: 1px solid #ccc;
    box-shadow: none;
    z-index: unset;
    position: relative; }
    .rstm-tree-item--active {
      color: white;
      background: rgb(146, 141, 141);
      border-bottom: none; }
    .rstm-tree-item--focused {
      box-shadow: 0 0 5px 0 #222;
      z-index: 999; }
  
  .rstm-search {
    padding: 1rem 1.5rem;
    background: #ccc;
    border: none;
    width: 100%; }