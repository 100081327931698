.Footer {
    color: white; 
    background-color:rgb(146, 141, 141);   
    position: fixed;
    padding-top: 10px;
    padding-bottom:10px;
    padding-left: 5px;
    padding-right: 5px;
    left:0;
    bottom:0;
    right:0;
  }

  .SocialIcons {
    color: cyan;

  }